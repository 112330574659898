import React, {useContext, useEffect, useState} from "react";
import { QrReader } from "react-qr-reader";
import { basketContext } from "../../providers/basketProvider/basketProvider";
import "./qrScanner.css";
import { InfoModal } from "./infoModal/infoModal";
import { ProductModal } from "./productModal/productModal";
import { ManualScanForm } from "./manualScanForm/manualScanForm";
import {apiCart} from "../../api/api.cart";

const url = "https://ginocerruti.com/item/";

const defaultInfoModal = {
  isOpen: false,
  text: "",
};

export const QrScanner = () => {
  const [startScan, setStartScan] = useState(false);
  const [infoModal, setInfoModal] = useState(defaultInfoModal);
  const [productCode, setProductCode] = useState(null);
  const { addItemToBasket, basket, clearAllBasket, transformBasket } = useContext(basketContext);

  const [serverResponse, setServerResponse] = useState(null);
  const [isSendingOrder, setSendingOrder] = useState(false);

  useEffect(() => {
    if (basket.length > 0) {
      sendBasketToBackend(basket);
    }
  }, [basket]);

  const sendBasketToBackend = async (basket) => {
    setSendingOrder(true);
    try {
      const response = await apiCart.sendFastOrder(transformBasket(basket));
      if (response?.productOrderId) {
        clearAllBasket();
        setServerResponse({
          success: true,
          message: "The order is confirmed. You will shortly receive an email confirmation.",
        });
      } else {
        setServerResponse({
          success: false,
          message: response,
        });
      }
    } catch (e) {
      setServerResponse({
        success: false,
        message: [e?.message],
      });
    } finally {
      setSendingOrder(false);
    }
  };
  const onResult = (result, error) => {
    try {
      if (result) {
        if (result?.text?.startsWith(url)) {
          const textAfterWord = result?.text?.slice(url.length)?.trim();
          setProductCode(textAfterWord?.toUpperCase());
          setStartScan(false);
        } else {
          setInfoModal({
            isOpen: true,
            text: "Please scan only Gino Cerutti QR codes.",
          });
        }
      }
    } catch (e) {
      setInfoModal({
        isOpen: true,
        text: "Unknown Error(",
      });
      console.info(e);
    }
  };

  const addProductToBasket = (products, image, customerName) => {
    products.forEach((product) => {
      addItemToBasket({
        id: `${product.code}_${product.productSizeName}_${product.productColorName}`,
        code: product.code,
        size: product.productSizeName,
        color: product.productColorName,
        count: 1,
        image,
        colorId: product.productColorId,
        sizeId: product.productSizeId,
        productId: product.productId,
        status: product.status,
        customerName: customerName,
      });
    });
    setProductCode(null);
  };

  return (
    <div
      className={`scannerContainer ${
        startScan ? "scannerContainerActive" : ""
      }`}
    >
      {startScan && (
        <>
          <QrReader
            constraints={{ facingMode: "environment" }}
            delay={1000}
            className="scannerBlock"
            videoContainerStyle={{
              padding: 0,
              width: "100%",
            }}
            videoStyle={{
              position: "relative",
              width: "100%",
            }}
            onResult={onResult}
            style={{ minWidth: "400px" }}
          />
        </>
      )}
      <button
        onClick={() => {
          setStartScan(!startScan);
        }}
        className="scanButton"
      >
        {startScan ? "Stop Scan" : "Start Scan with QR code"}
      </button>
      <ManualScanForm setCode={setProductCode} />
      <InfoModal
        isOpen={infoModal.isOpen}
        text={infoModal.text}
        onClick={() => setInfoModal(defaultInfoModal)}
      />

      <ProductModal
          onClose={() => setProductCode(null)}
          code={productCode}
          onClick={addProductToBasket}
      />

      {isSendingOrder && (
        <div>
          <div className="infoModalWrapper">
            <div className="infoModalContainer">
              <p className="infoModalText">Sending...</p>
            </div>
          </div>
        </div>
      )}

      {serverResponse && serverResponse.success === true && (
        <div className="infoModalWrapper">
          <div className="infoModalContainer">
            <p className="basketModalTitle">The order is confirmed. You will shortly receive an email confirmation.</p>
            <button onClick={() => setServerResponse({})}className="infoModalButton">
              OK
            </button>
          </div>
        </div>
    )}

      {serverResponse && serverResponse.success === false && (
          <div className="infoModalWrapper">
            <div className="infoModalContainer">
              <div className="basketModalTitle errorContainer">
                {serverResponse.message?.map((item) => (
                    <div key={item}>{item}.</div>
                ))}
              </div>
              <button
                  onClick={() => setServerResponse({})}
                  className="infoModalButton"
              >
                OK
              </button>
            </div>
          </div>
      )}
    </div>
  );
};
