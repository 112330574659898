import React, { useEffect, useState } from "react";
import "./productModal.css";
import { apiProduct } from "../../../api/api.product";
import { MenuItem, Select } from "@mui/material";

export function ProductModal(props) {
  const { code, onClick, onClose } = props;

  const [productInfo, setProductInfo] = useState(null);
  const [errorState, setErrorState] = useState(null);
  const [notFoundState, setNotFoundState] = useState(false);
  const [inputState, setInputState] = useState("");

  useEffect(() => {
    if (code) getProductInfo(code);
  }, [code]);

  const getProductInfo = async (code) => {
    try {
      const dataInfo = await apiProduct.getProductInfo(code);
      const dataImage = await apiProduct.getProductImage(code);
      const colors = [
        ...new Set(dataInfo.map((item) => item.productColorName)),
      ];
      setProductInfo({
        images: dataImage.map((item) => item.url),
        info: dataInfo,
        colors,
        activeColor: colors[0],
        activeProducts: [],
      });
    } catch (e) {
      setNotFoundState(true);
    }
  };

  const handleChangeColor = (e) => {
    const activeColor = e.target.value;
    setProductInfo((prevState) => ({
      ...prevState,
      activeColor,
      activeProducts: [],
    }));
  };

  const getProductStatus = (product) => {
    if (product.quantityInStock > 0) return "inStock";
    if (product.quantityInProduction > 0) return "inProduction";
    return "forwardOrder";
  };

  const handleChangeSize = (isActive, newProduct) => {
    if (
      !isActive &&
      newProduct.isDiscontinued &&
      !newProduct.quantityInProduction &&
      !newProduct.quantityInStock
    ) {
      setErrorState("This product is not available");
      return;
    }
    if (
      !isActive &&
      newProduct.isFOProductSizeColorDisabled &&
      !newProduct.quantityInProduction &&
      !newProduct.quantityInStock
    ) {
      setErrorState(
        "Forward order is not available for this size-colour combination"
      );
      return;
    }

    if (!isActive && newProduct.restricted && !!newProduct.restrictedReason && newProduct.restrictedReason.length) {
      setErrorState(
          `${newProduct.restrictedReason} <br><a href="https://wa.me/447405978227">WhatsApp us</a>`
      );
      return;
    } else if (!isActive && newProduct.restricted) {
      setErrorState(
        'Please <a href="https://wa.me/447405978227">WhatsApp us</a> if you still want to order this dress'
      );
      return;
    }

    setProductInfo((prevState) => {
      const activeProducts = isActive
        ? [
            ...prevState.activeProducts.filter(
              (item) => item.productSizeName !== newProduct.productSizeName
            ),
          ]
        : [
            ...prevState.activeProducts,
            { ...newProduct, status: getProductStatus(newProduct) },
          ];
      return {
        ...prevState,
        activeProducts,
      };
    });
  };

  if (!code) return null;

  if (notFoundState)
    return (
      <div className="productModalWrapper">
        <div className="productModalContainer">
          <div className="disableProductModalWrapper">
            <div className="disableProductModal">
              <div className="disableProductModalText">Product not found</div>
              <button
                onClick={() => {
                  onClose();
                  setErrorState(null);
                  setNotFoundState(false);
                }}
                className="productModalButton"
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      </div>
    );

  return (
    <div className="productModalWrapper">
      <div className="productModalContainer">
        {productInfo ? (
          <>
            <img className="productModalImage" src={productInfo.images[0]} />
            <div className="productCode">{code}</div>
            <div className="productModalColors">
              <span>Color: </span>
              <Select
                onChange={handleChangeColor}
                id="select"
                value={productInfo.activeColor}
              >
                {productInfo.colors.map((color) => (
                  <MenuItem key={color} value={color}>
                    {color.charAt(0).toUpperCase() + color.slice(1)}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="productModalSizes">
              {productInfo.info
                .filter(
                  (item) => item.productColorName === productInfo.activeColor
                )
                .map((item) => {
                  const newSize = item.productSizeName;
                  const isActive = productInfo.activeProducts.find(
                    (product) => product.productSizeName === newSize
                  );
                  return (
                    <span
                      className={`productSizeChip 
                      ${item.quantityInStock ? "inStock" : ""}
                      ${item.quantityInProduction ? "inProd" : ""}
                      ${
                        !item.quantityInProduction && !item.quantityInStock
                          ? "inOrder"
                          : ""
                      }
                      ${isActive ? "productSizeChipActive" : ""} 
                      ${
                        item.isFOProductSizeColorDisabled &&
                        !item.quantityInProduction &&
                        !item.quantityInStock
                          ? "FODisabled"
                          : ""
                      }
                      ${
                        item.isDiscontinued &&
                        !item.quantityInProduction &&
                        !item.quantityInStock
                          ? "productIsDiscontinued"
                          : ""
                      }
                      `}
                      key={newSize}
                      onClick={() => handleChangeSize(isActive, item)}
                    >
                      {newSize}
                    </span>
                  );
                })}
            </div>
            {productInfo.activeProducts.length > 0 && (
                <div className="selectedSizes">
                  <span className="selectedSizesTitle">Approximate ETA for selected sizes:</span>
                  <ul className="selectedSizesList">
                    {productInfo.activeProducts.map((product) => {
                      const formatDate = (dateString) => {
                        if (!dateString) return "Unknown ETA";
                        const [year, month, day] = dateString.split(" ")[0].split("-");
                        return `${day}-${month}-${year}`;
                      };

                      return (
                          <li key={product.productSizeName} className="selectedSizeItem">
                            <span className="size">{product.productSizeName} :</span>
                            <span className="eta">{formatDate(product.eta)}</span>
                          </li>
                      );
                    })}
                  </ul>
                </div>
            )}


            <input
              placeholder="Customer name"
              className="inputField"
              value={inputState}
              onChange={(e) => setInputState(e.target.value)}
            />
            <div className="productModalButtonContainer">
              <button
                disabled={!productInfo.activeProducts.length}
                onClick={() => {
                  onClick(
                    productInfo.activeProducts,
                    productInfo.images[0],
                    inputState
                  );
                  setProductInfo(null);
                  setInputState("");
                }}
                className="productModalButton"
              >
                {/*Add to basket*/}
                Send To Order
              </button>
              <button
                onClick={() => {
                  onClose();
                  setProductInfo(null);
                }}
                className="productModalButton"
              >
                Close
              </button>
            </div>
          </>
        ) : (
          <div className="loadingModal">Loading...</div>
        )}
        {errorState && (
          <div className="disableProductModalWrapper">
            <div className="disableProductModal">
              <div
                className="disableProductModalText"
                dangerouslySetInnerHTML={{ __html: errorState }}
              />
              <button
                onClick={() => setErrorState(null)}
                className="productModalButton"
              >
                Ok
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
