import React, { useContext, useState } from "react";
import { Header } from "../../components/header/header";
import { basketContext } from "../../providers/basketProvider/basketProvider";
import "./basketScreen.css";
import { SendManagerModal } from "./sendManagerModal/sendManagerModal";
import { Link } from "react-router-dom";
import { FastOrderModal } from "./fastOrderModal/fastOrderModal";
import { sessionContext } from "../../providers/sessionProvider/sessionProvider";

export function BasketScreen() {
  const {
    basket,
    deleteItem,
    incrementItemCount,
    decrementItemCount,
    clearAllBasket,
    setItemCount,
    checkItemCount,
  } = useContext(basketContext);

  const { session } = useContext(sessionContext);

  const [isOpenModal, setOpenModal] = useState(false);
  const [isOpenOrderModal, setOpenOrderModal] = useState(false);

  const handleChangeCount = (e) => {
    const newCount = +e.target.value;
    const id = e.target.name;
    setItemCount(id, newCount);
  };

  const handleBlurCount = (e) => {
    const id = e.target.name;
    checkItemCount(id);
  };

  return (
    <div>
      <Header />
      <div className="basketPage">
        <Link to="/" className="backButton">
          Back to scan
        </Link>
        {Boolean(basket.length) && (
          <>
            <div className="basketButtonBlock">
              <button className="clearButton" onClick={clearAllBasket}>
                Clear Basket
              </button>
              <button
                className="sendManagerButton"
                onClick={() => setOpenModal(true)}
              >
                Send To Order
              </button>
            </div>
            {session?.email === "mail@ginocerruti.co.uk" && (
              <button
                className="fastOrderButton"
                onClick={() => setOpenOrderModal(true)}
              >
                Buy Now
              </button>
            )}
          </>
        )}
        <p className="basketTitle">
          Your basket {!Boolean(basket.length) && "is empty"}
        </p>
        <div className="basketList">
          {basket.map((item) => (
            <div key={item.id} className="basketItem">
              <img src={item.image} className="basketImage" />
              <div className="basketContent">
                <div className={`basketTitleBlock ${item.status}`}>
                  <span>{item.code}</span>
                  <span>size: {item.size}</span>
                  <span>color: {item.color}</span>
                </div>

                <div className="basketCustomerName">
                  Customer name: {item.customerName || ""}
                </div>

                <div className="basketButtonBlock">
                  <button
                    className="buttons"
                    onClick={() => decrementItemCount(item.id)}
                  >
                    -
                  </button>
                  <input
                    className="inputField basketItemCount"
                    value={item.count}
                    name={item.id}
                    type="number"
                    onBlur={handleBlurCount}
                    onChange={handleChangeCount}
                  />
                  <button
                    className="buttons"
                    onClick={() => incrementItemCount(item.id)}
                  >
                    +
                  </button>
                  <button
                    className="buttons deleteProductBtn"
                    onClick={() => deleteItem(item.id)}
                  >
                    <svg
                      onClick={() => deleteItem(item.id)}
                      width="20px"
                      height="20px"
                      color="black"
                      viewBox="0 0 24 24"
                    >
                      <path d="M10.5 9H9v9h1.5V9zM15 9h-1.5v9H15V9z" />
                      <path d="M3 4.5V6h1.5v15A1.5 1.5 0 006 22.5h12a1.5 1.5 0 001.5-1.5V6H21V4.5H3zM6 21V6h12v15H6zM15 1.5H9V3h6V1.5z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <SendManagerModal
        isOpen={isOpenModal}
        onClick={() => setOpenModal(false)}
      />
      <FastOrderModal
        isOpen={isOpenOrderModal}
        onClick={() => setOpenOrderModal(false)}
      />
    </div>
  );
}
