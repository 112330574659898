import React, { useContext, useState } from "react";
import "./sendManagerModal.css";
import { apiProduct } from "../../../api/api.product";
import { basketContext } from "../../../providers/basketProvider/basketProvider";
import { apiCart } from "../../../api/api.cart";
import { sessionContext } from "../../../providers/sessionProvider/sessionProvider";

export function SendManagerModal(props) {
  const { isOpen, onClick } = props;

  const { session } = useContext(sessionContext);
  const { basket, clearAllBasket, transformBasket } = useContext(basketContext);
  const [isSuccess, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [isDisabled, setDisabled] = useState(false);

  const onSubmit = async () => {
    setDisabled(true);
    try {
      const response = await apiCart.sendBasket(transformBasket(basket));
      if (Array.isArray(response)) {
        setError(response);
      } else {
        await apiProduct.sendBasket({ basket, email: session.email });
        clearAllBasket();
        setSuccess(true);
      }
    } catch (e) {
      console.info(e);
      setError([e?.message]);
    } finally {
      setDisabled(false);
    }
  };

  if (!isOpen) return null;

  if (error)
    return (
      <div className="infoModalWrapper">
        <div className="infoModalContainer">
          <div className="basketModalTitle errorContainer">
            {error?.map((item) => (
              <div key={item}>{item}.</div>
            ))}
          </div>
          <button
            onClick={() => {
              onClick();
              setError(null);
            }}
            className="infoModalButton"
          >
            OK
          </button>
        </div>
      </div>
    );

  return (
    <div className="infoModalWrapper">
      {!isSuccess ? (
        <div className="infoModalContainer">
          <p className="basketModalTitle">
            Send all items to your shopping cart on the website?
          </p>
          <div className="basketSubmitBlock">
            <button
              disabled={isDisabled}
              onClick={onSubmit}
              className="infoModalButton"
            >
              {isDisabled ? "..." : "SEND"}
            </button>
            <button onClick={onClick} className="infoModalButton">
              CLOSE
            </button>
          </div>
        </div>
      ) : (
        <div className="infoModalContainer">
          <p className="basketModalTitle">Order received</p>
          <button onClick={onClick} className="infoModalButton">
            OK
          </button>
        </div>
      )}
    </div>
  );
}
