import React, { useState } from "react";
import "./manualScanForm.css";

export function ManualScanForm(props) {
  const { setCode } = props;

  const [state, setState] = useState("");

  const submitForm = (e) => {
    e.preventDefault();
    const code = state.toLowerCase().trim();
    if (code) {
      setCode(code.toUpperCase());
      setState("");
    }
  };

  return (
    <form className="scanForm" onSubmit={submitForm}>
      <span className="scanHint">You can also search the code manually</span>
      <input
        placeholder="Enter code"
        className="scanInput"
        value={state}
        onChange={(e) => setState(e.target.value)}
      />
      <button className="scanBtn" type="submit">
        Search
      </button>
    </form>
  );
}
